<template>
  <vue-scroll>
      <div class="box">
        <h2>Check your email</h2>
      </div>
  </vue-scroll>
</template>

<script>
export default {
  name: 'AfterForgot',

  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
    setTimeout(() => {
      this.$onCommandParams('login')
    }, 1000 * 3)
  }
}
</script>
